import React, { useRef, useState, useEffect } from "react";
import * as tf from "@tensorflow/tfjs";
import Webcam from "react-webcam";
import { createWorker } from "tesseract.js";
import "./detect.css";
import { drawRect, drawText } from "./utilities";
import { CLASSES } from "./labels";
import { Buffer } from "buffer";
import Camera from "react-html5-camera-photo";

function Detect() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  const [picture, setPicture] = useState("");

  var intervalId = null;

  // Main function
  const runModel = async () => {
    loadModel();
  };

  const sendPictureToMobileApp = (pictureSrc) => {
    console.log("sendPictureToMobileApp");
    if (window) {
      if (window.ReactNativeWebView) {
        console.log("picture received", pictureSrc);
        window.ReactNativeWebView.postMessage({
          source: "picture",
          image: pictureSrc,
        });
      }
    }
  };

  const loadModel = async () => {
    console.log("in loadModel");

    // const modelUrl = "mobilenet/model.json";

    const modelUrl = "mobilenet/model.json";
    try {
      const model = await tf.loadGraphModel(modelUrl);

      console.log("model loaded");

      intervalId = setInterval(() => {
        detect(model);
      }, 10);

      console.log("intervalId", intervalId);
      return model;
    } catch (err) {
      console.log("err", err);
    }
    return null;
  };

  const detect = async (model) => {
    console.log("in detect");
    // Check data is available
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null &&
      webcamRef.current.video.readyState === 4
    ) {
      // Get Video Properties
      const video = webcamRef.current.video;
      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;

      // Set video width
      webcamRef.current.video.width = videoWidth;
      webcamRef.current.video.height = videoHeight;

      const tfImg = tf.browser.fromPixels(video);
      const smalImg = tf.image.resizeBilinear(tfImg, [224, 224]);
      const resized = tf.cast(smalImg, "float32");
      const t4d = tf.tensor4d(Array.from(resized.dataSync()), [1, 224, 224, 3]);

      let boxes = [];
      let classes = [];
      let scores = [];

      let objectList = [];

      await model
        .executeAsync(t4d.toInt(), [
          "Identity_4:0",
          "Identity_2:0",
          "detection_boxes",
          "detection_multiclass_scores",
        ])
        .then((predictions) => {
          clearInterval(intervalId);
          scores = predictions[0].dataSync();
          console.log("Identity_4:0, Scores", predictions[0].dataSync());
          classes = predictions[1].dataSync();
          // console.log("Classes. Identity_2:0", classes);
          boxes = predictions[2].arraySync();
          // console.log("detection_boxes", boxes);

          // console.log("detection_scores", predictions[4].arraySync());
        });

      for (let i = 0; i < classes.length; i++) {
        if (scores[i] * 100 > 40) {
          let ymin = boxes[0][i][0] * videoHeight;
          let xmin = boxes[0][i][1] * videoWidth;
          let ymax = boxes[0][i][2] * videoHeight;
          let xmax = boxes[0][i][3] * videoWidth;
          let obj = {
            bbox: [xmin, ymin, xmax - xmin, ymax - ymin],
            class:
              CLASSES[classes[i]].name +
              " - " +
              (scores[i] * 100).toFixed(2) +
              "% ",
          };

          objectList.push(obj);
        }
      }

      if (objectList.length > 2) {
        console.log("intervalId", intervalId);
        clearInterval(intervalId);
        capture();
      }
      // Set canvas height and width
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;

      const ctx = canvasRef.current.getContext("2d");
      drawRect(objectList, ctx);
    }
  };

  const capture = React.useCallback(() => {
    if (picture == "") {
      console.log("in caputre");
      const pictureSrc = webcamRef.current.getScreenshot();
      setPicture(pictureSrc);
      console.log("sending picture to Mobile App");
      sendPictureToMobileApp(pictureSrc);
    }
  });

  useEffect(() => {
    if (picture != "") {
      console.log("picture", picture);
      // readText();
    }
  }, [picture]);

  const readText = async () => {
    const worker = await createWorker({
      logger: (m) => console.log(m),
    });

    // if (picture != "") {
    await worker.load();
    await worker.loadLanguage("eng");
    await worker.initialize("eng");
    //   console.log("picture", picture);
    //   let imageBuffer = Buffer.from(picture, "base64");
    const {
      data: { text },
    } = await worker.recognize(picture);
    console.log(text);
    await worker.terminate();
    // }
  };

  useEffect(() => {
    // alert("useEffect");
    runModel();
    // readText();
  }, []);

  return (
    <div>
      {/* <video
        id="videoPlayer"
        muted
        autoPlay
        // className={props.facing === "user" ? "videoFlipStyle" : ""}
        style={{
          objectFit: "cover",
          objectPosition: "top",
          width: "100vw",
          height: "100vh",
          zoom: zoomScale,
        }}
        onPlay={resizeCanvas}
      ></video> */}
      <Webcam
        ref={webcamRef}
        muted={true}
        style={{
          position: "absolute",
          marginLeft: "auto",
          marginRight: "auto",
          left: 0,
          right: 0,
          textAlign: "center",
          zindex: 9,
          width: 640,
          height: 480,
        }}
      />

      {/* <Camera
        // onTakePhoto={(dataUri) => {
        //   handleTakePhoto(dataUri);
        // }}
        style={{
          position: "absolute",
          marginLeft: "auto",
          marginRight: "auto",
          left: 0,
          right: 0,
          textAlign: "center",
          zindex: 9,
          width: 640,
          height: 480,
        }}
      /> */}

      <canvas
        ref={canvasRef}
        style={{
          position: "absolute",
          marginLeft: "auto",
          marginRight: "auto",
          left: 0,
          right: 0,
          textAlign: "center",
          zindex: 8,
          width: 640,
          height: 480,
        }}
      />
      <img
        src={picture}
        width={"500px"}
        height={"500px"}
        style={{ marginTop: "480px" }}
      />
    </div>
  );
}

export default Detect;
