import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Detect from "./Components/DetectCNIC/detect";
import React from "react";

function App() {
  React.useEffect(() => {
    console.log("here");
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="detect" element={<Detect />} />
      </Routes>
    </div>
  );
}

export default App;
