export const CLASSES = {
  1: {
    id: 1,
    name: "Name",
  },
  2: {
    id: 2,
    name: "Father Name",
  },
  3: {
    id: 3,
    name: "Picture",
  },
  4: {
    id: 4,
    name: "Country",
  },
  5: {
    id: 5,
    name: "CNIC Number",
  },
  6: {
    id: 6,
    name: "DOB",
  },
  7: {
    id: 7,
    name: "Date of Issue",
  },
  8: {
    id: 8,
    name: "Date of Expiry",
  },
  9: {
    id: 9,
    name: "Signature",
  },
  10: {
    id: 10,
    name: "Flag",
  },
  11: {
    id: 11,
    name: "NIC Heading",
  },
  12: {
    id: 12,
    name: "Country Name",
  },
  13: {
    id: 13,
    name: "Logo",
  },
  14: {
    id: 14,
    name: "Card",
  },
};
