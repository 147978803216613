import "./Home.css";

function Home() {
  const showDeviceCameraForFace = () => {
    console.log("showDeviceCameraForFace");
    if (window) {
      if (window.ReactNativeWebView) {
        console.log("message received");
        showLoader(1);
        window.ReactNativeWebView.postMessage("facecamera");
      }
    }
  };

  const showDeviceCameraForCNIC = () => {
    console.log("showDeviceCameraForCNIC");
    if (window) {
      if (window.ReactNativeWebView) {
        showLoader(2);
        window.ReactNativeWebView.postMessage("cniccamera");
      }
    }
  };

  function showLoader(num) {
    let loader = document.getElementById("loader" + num);
    if (loader) loader.style.visibility = "visible";
  }

  function hideLoader(num) {
    let loader = document.getElementById("loader" + num);
    if (loader) loader.style.visibility = "hidden";
  }

  document.addEventListener("message", (message) => {
    const responseData = JSON.parse(message.data);
    console.log(responseData);

    if (responseData.type === "selfie") {
      const img = document.getElementById("selfieImage");
      if (img) {
        img.onload = () => {
          hideLoader(1);
        };
        img.src = "data:image/jpeg;base64," + responseData.image;
      }
    }

    if (responseData.type === "cnic") {
      const img = document.getElementById("cnicImage");
      if (img) {
        img.onload = () => {
          hideLoader(2);
        };
        img.src = "data:image/jpeg;base64," + responseData.data.cnic_image;
      }

      const dataDiv = document.getElementById("cnic_data");
      if (dataDiv) {
        const cnicData = responseData.data.cnic_data;

        const dataHTML = Object.entries(cnicData).map(
          ([label, data]) =>
            '<div style={{flexDirection: "row",justifyContent: "space-between"}}>' +
            label +
            ": " +
            data.labelValue +
            "</div>"
        );

        dataDiv.innerHTML = dataHTML.join("");
      }
    }
  });

  return (
    <div className="App">
      <header className="App-header">
        <div className="container">
          <div className="card">
            <header>Face & Liveness Detection</header>
            <div className="action">
              <span
                className="material-symbols-outlined"
                onClick={showDeviceCameraForFace}
              >
                photo_camera
              </span>
            </div>
            <div id="loader1" className="loader"></div>
            <img id="selfieImage" style={{ width: "200px" }} />
          </div>
          <div className="card" style={{ marginTop: "16px" }}>
            <header>CNIC OCR</header>
            <div className="action">
              <span
                className="material-symbols-outlined"
                onClick={showDeviceCameraForCNIC}
              >
                photo_camera
              </span>
            </div>
            <div id="loader2" className="loader"></div>
            <div
              id="cnic_data"
              style={{ width: "100%", paddingBottom: "8px" }}
            ></div>
            <img id="cnicImage" style={{ width: "200px" }} />
          </div>
          {/* <div className="card" style={{ marginTop: "16px" }}>
            <header>
              <Link to="/detect">Detect CNIC</Link>
            </header>
            <div className="action">
              <span className="material-symbols-outlined">photo_camera</span>
            </div>
            <div id="loader2" className="loader"></div>
            <div
              id="cnic_data"
              style={{ width: "100%", paddingBottom: "8px" }}
            ></div>
            <img id="cnicImage" style={{ width: "200px" }} />
          </div> */}
        </div>
      </header>
    </div>
  );
}

export default Home;
